define("discourse/plugins/swapd-user-feedback/discourse/components/rating-composer", ["exports", "discourse/models/user-action", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "@ember/component", "@ember/array"], function (_exports, _userAction, _ajax, _decorators, _ajaxError, _component, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { emojiUnescape } from 'discourse/lib/text';
  var _default = _exports.default = _component.default.extend(dt7948.p({
    rating: 0,
    raw: "",
    isSeller: false,
    isBuyer: false,
    actions: {
      save() {
        if (this.disabled) return;
        this.set("loading", true);
        const stream = this.get("stream");
        const user = stream.get("user");
        (0, _ajax.ajax)(`/user-feedback/u/${user.id}.json`, {
          type: "POST",
          data: {
            raw: this.raw,
            rating: this.rating,
            posted_by: this.postedBy
          }
        }).then(data => {
          const action = data.rating;
          const copy = (0, _array.A)();
          copy.pushObject(_userAction.default.create(action));
          stream.get("content").insertAt(0, _userAction.default.collapseStream(copy)[0]);
          stream.set("itemsLoaded", stream.get("itemsLoaded") + 1);
          this.setProperties({
            rating: 0,
            raw: "",
            isBuyer: false,
            isSeller: false
          });
          user.setProperties({
            average_rating: data.average_rating,
            ratings_count: data.ratings_count,
            feedback_limited: data.feedback_limited
          });
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("loading", false);
        });
      }
    },
    disabled(loading, rating, raw, postedBy, limited) {
      if (limited) return true;
      if (loading) return true;
      if (rating < 1) return true;
      if (raw.trim().length < 1) return true;
      if (!postedBy) return true;
      return false;
    },
    postedBy(isSeller, isBuyer) {
      if (isSeller) return "seller";
      if (isBuyer) return "buyer";
      return null;
    },
    _nullBuyer() {
      if (this.isSeller) {
        this.set("isBuyer", false);
        this.notifyPropertyChange("isBuyer");
      }
    },
    _nullSeller() {
      if (this.isBuyer) {
        this.set("isSeller", false);
        this.notifyPropertyChange("isSeller");
      }
    }
  }, [["method", "disabled", [(0, _decorators.default)("loading", "rating", "raw", "postedBy", "stream.user.feedback_limited")]], ["method", "postedBy", [(0, _decorators.default)("isSeller", "isBuyer")]], ["method", "_nullBuyer", [(0, _decorators.observes)("isSeller")]], ["method", "_nullSeller", [(0, _decorators.observes)("isBuyer")]]]));
});