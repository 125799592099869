define("discourse/plugins/swapd-user-feedback/discourse/components/feedback-stream", ["exports", "discourse/components/user-stream", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service", "@ember/application", "discourse/plugins/swapd-user-feedback/discourse/components/modal/dispute-feedback", "@ember/object", "@glimmer/tracking"], function (_exports, _userStream, _ajax, _ajaxError, _service, _application, _disputeFeedback2, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FeedbackStreak extends _userStream.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    deleteFeedback(id) {
      this.dialog.confirm({
        message: I18n.t("user_feedback.confirm_delete"),
        confirmButtonClass: 'btn-danger',
        didConfirm: () => {
          this.performDeleteFeedback(id);
        },
        didCancel: () => {
          return;
        }
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "deleteFeedback", [_object.action]))();
    disputeFeedback(feedback) {
      let modal = (0, _application.getOwner)(this).lookup("service:modal");
      const result = modal.show(_disputeFeedback2.default, {
        model: {
          user: this.stream.user,
          raw: "",
          feedback: feedback
        }
      });
      modal.setProperties({
        loading: this.get("isLoading"),
        feedback,
        user: this.stream.user,
        raw: ""
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "disputeFeedback", [_object.action]))();
    clearDispute(feedback) {
      this.dialog.confirm({
        message: I18n.t("user_feedback.confirm_clear_dispute"),
        confirmButtonClass: 'btn-danger',
        didConfirm: () => {
          this.performClearDispute(feedback);
        },
        didCancel: () => {
          return;
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "clearDispute", [_object.action]))();
    performDeleteFeedback(id) {
      this.set("isLoading", true);
      const stream = this.get("stream");
      (0, _ajax.ajax)(`/user-feedback/u/${stream.user.id}/${id}.json`, {
        type: "DELETE"
      }).then(data => {
        const action = stream.content.findBy("id", id);
        if (action) {
          stream.content.removeObject(action);
          stream.set("itemsLoaded", stream.itemsLoaded - 1);
        }
        stream.user.setProperties({
          average_rating: data.average_rating,
          ratings_count: data.ratings_count
        });
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("isLoading", false));
    }
    performClearDispute(feedback) {
      this.set("isLoading", true);
      const stream = this.get("stream");
      (0, _ajax.ajax)(`/user-feedback/u/${stream.user.id}/${feedback.id}/clear`, {
        type: "POST"
      }).then(data => {
        feedback.set("disputed", false);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("isLoading", false));
    }
  }
  _exports.default = FeedbackStreak;
});