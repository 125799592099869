define("discourse/plugins/swapd-user-feedback/discourse/routes/user-activity-feedback", ["exports", "discourse/routes/user-activity-stream", "discourse/models/user-action", "discourse/plugins/swapd-user-feedback/discourse/models/ratings-stream"], function (_exports, _userActivityStream, _userAction, _ratingsStream) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _userActivityStream.default.extend({
    userActionType: _userAction.default.TYPES["posts"],
    beforeModel(transition) {
      if (!this.siteSettings.user_feedback_enabled) {
        this.transitionTo('userActivity', this.modelFor("user"));
      }
    },
    model() {
      return _ratingsStream.default.create({
        user: this.modelFor("user")
      });
    },
    afterModel(model, transition) {
      model.filterBy(this.get("userActionType"), this.get("noContentHelpKey"));
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('user', this.modelFor("user"));
    },
    renderTemplate() {
      this.render('user-activity-feedback');
    }
  });
});