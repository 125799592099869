define("discourse/plugins/swapd-user-feedback/discourse/components/modal/dispute-feedback", ["exports", "@ember/component", "discourse/lib/ajax-error", "discourse/lib/ajax", "discourse-common/utils/decorators", "@ember/object", "@ember/template-factory"], function (_exports, _component, _ajaxError, _ajax, _decorators, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="dispute-feedback"
    id="dispute-feedback"
    @closeModal={{@closeModal}}
  >
    <:body>
      <p>{{{i18n "user_feedback.enter_dispute_reason"}}}</p>
  
      <Textarea
        @value={{this.raw}}
        rows="5"
        class="rating-textarea"
      />
    </:body>
    <:footer>
      <DButton
        class="btn-danger btn-large"
        @action={{action "dispute"}}
        @disabled={{this.disabled}}
        @label="user_feedback.confirm"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "tmrEmzXd",
    "block": "[[[8,[39,0],[[24,0,\"dispute-feedback\"],[24,1,\"dispute-feedback\"]],[[\"@closeModal\"],[[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[2,[28,[37,1],[\"user_feedback.enter_dispute_reason\"],null]],[13],[1,\"\\n\\n    \"],[8,[39,2],[[24,\"rows\",\"5\"],[24,0,\"rating-textarea\"]],[[\"@value\"],[[30,0,[\"raw\"]]]],null],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-danger btn-large\"]],[[\"@action\",\"@disabled\",\"@label\"],[[28,[37,4],[[30,0],\"dispute\"],null],[30,0,[\"disabled\"]],\"user_feedback.confirm\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"textarea\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/swapd-user-feedback/discourse/components/modal/dispute-feedback.hbs",
    "isStrictMode": false
  });
  class DisputeFeedback extends _component.default {
    loading = false;
    feedback = null;
    raw = "";
    dispute() {
      this.set("loading", true);
      (0, _ajax.ajax)(`/user-feedback/u/${this.model.user.id}/${this.model.feedback.id}/dispute`, {
        type: "POST",
        data: {
          raw: this.raw
        }
      }).then(() => {
        this.model.feedback.set("disputed", true);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.closeModal();
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "dispute", [_object.action]))();
    disabled(loading, raw) {
      if (loading) return true;
      if (raw.trim().length < 1) return true;
      return false;
    }
    static #_2 = (() => dt7948.n(this.prototype, "disabled", [(0, _decorators.default)("loading", "raw")]))();
  }
  _exports.default = DisputeFeedback;
  ;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DisputeFeedback);
});